import React from 'react';
import LoginForm from './components/LoginForm/LoginForm';

function App() {
  return (
    <div>
      <LoginForm />

    </div>
  );
}

export default App;
